@tailwind base;
@tailwind components;
@tailwind utilities;

.cellAnimation {
  animation: flip linear;
  animation-duration: 0.15s;
  border-color: black;
}

.cellAnimation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
  border-color: black;
  @dark border-color: white;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

.completed div:not(.shown) {
  animation: rownope linear;
  animation-duration: 0.15s;
  background-color: transparent !important;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}



@-webkit-keyframes rownope {
    10% {
        transform: translateX(-14px);
    }
    20% {
        transform: translateX(14px);
    }
    30% {
        transform: translateX(-8px);
    }
    40% {
        transform: translateX(8px);
    }
    50% {
        transform: translateX(-4px);
    }
    60% {
        transform: translateX(4px);
    }
    70% {
        transform: translateX(-2px);
    }
    80% {
        transform: translateX(2px);
    }
    90% {
        transform: translateX(-1px);
    }
    to {
        transform: translateX(1px);
    }
}
@keyframes rownope {
    10% {
        transform: translateX(-14px);
    }
    20% {
        transform: translateX(14px);
    }
    30% {
        transform: translateX(-8px);
    }
    40% {
        transform: translateX(8px);
    }
    50% {
        transform: translateX(-4px);
    }
    60% {
        transform: translateX(4px);
    }
    70% {
        transform: translateX(-2px);
    }
    80% {
        transform: translateX(2px);
    }
    90% {
        transform: translateX(-1px);
    }
    to {
        transform: translateX(1px);
    }
}


@-webkit-keyframes happy {
    10% {
        transform: translateY(calc(var(--happy-jump) * -0.3));
    }
    20% {
        transform: translateY(calc(var(--happy-jump) * -0.58));
    }
    30% {
        transform: translateY(calc(var(--happy-jump) * -0.8));
    }
    40% {
        transform: translateY(calc(var(--happy-jump) * -0.95));
    }
    50% {
        transform: translateY(calc(var(--happy-jump) * -1));
    }
    60% {
        transform: translateY(calc(var(--happy-jump) * -0.95));
    }
    70% {
        transform: translateY(calc(var(--happy-jump) * -0.8));
    }
    80% {
        transform: translateY(calc(var(--happy-jump) * -0.58));
    }
    90% {
        transform: translateY(calc(var(--happy-jump) * -0.3));
    }
    to {
        transform: translateY(0);
    }
}
@keyframes happy {
    10% {
        transform: translateY(calc(var(--happy-jump) * -0.3));
    }
    20% {
        transform: translateY(calc(var(--happy-jump) * -0.58));
    }
    30% {
        transform: translateY(calc(var(--happy-jump) * -0.8));
    }
    40% {
        transform: translateY(calc(var(--happy-jump) * -0.95));
    }
    50% {
        transform: translateY(calc(var(--happy-jump) * -1));
    }
    60% {
        transform: translateY(calc(var(--happy-jump) * -0.95));
    }
    70% {
        transform: translateY(calc(var(--happy-jump) * -0.8));
    }
    80% {
        transform: translateY(calc(var(--happy-jump) * -0.58));
    }
    90% {
        transform: translateY(calc(var(--happy-jump) * -0.3));
    }
    to {
        transform: translateY(0);
    }
}
@-webkit-keyframes flip {
    0% {
        border: 4px solid #4c4347;
        transform: perspective(200px) rotateY(0deg);
    }
    49.99999% {
        border: 4px solid #4c4347;
        transform: perspective(200px) rotateY(90deg);
    }
    50% {
        border: none;
        transform: perspective(200px) rotateY(-90deg);
    }
    50.00001% {
        border: none;
    }
    to {
        transform: perspective(200px) rotateY(0deg);
    }
}
@keyframes flip {
    0% {
        border: 4px solid #4c4347;
        transform: perspective(200px) rotateY(0deg);
    }
    49.99999% {
        border: 4px solid #4c4347;
        transform: perspective(200px) rotateY(90deg);
    }
    50% {
        border: none;
        transform: perspective(200px) rotateY(-90deg);
    }
    50.00001% {
        border: none;
    }
    to {
        transform: perspective(200px) rotateY(0deg);
    }
}

@-webkit-keyframes type {
    0% {
        color: transparent;
        transform: perspective(200px) translateZ(0);
    }
    24.99999% {
        color: transparent;
    }
    25% {
        color: #fafaff;
        transform: perspective(200px) translateZ(30px);
    }
    to {
        color: #fafaff;
        transform: perspective(200px) translateZ(0);
    }
}
@keyframes type {
    0% {
        color: transparent;
        transform: perspective(200px) translateZ(0);
    }
    24.99999% {
        color: transparent;
    }
    25% {
        color: #fafaff;
        transform: perspective(200px) translateZ(30px);
    }
    to {
        color: #fafaff;
        transform: perspective(200px) translateZ(0);
    }
}
